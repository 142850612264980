<i18n>
	{
	"en": {
			"applyForSubchapter": "apply for subchapter",
			"newSubchapterApplication": "New subchapter application",
			"noItemsText": "No subchapters found",
			"reasonToJoin": "You can also join one or more of SAFAs five subchapters, which focus on a specific theme. In subchapters, you network, influence and learn new things."
		},
	"fi": {
			"applyForSubchapter": "Liity alaosastoon",
			"newSubchapterApplication": "Uusi alaosastohakemus",
			"noItemsText": "Alaosastoja ei löytynyt",
			"reasonToJoin": "Voit liittyä yhteen tai useampaan SAFAn viidestä alaosastosta, joiden toiminta on keskittynyt tietyn teeman ympärille. Alaosastoissa verkostoidut, vaikutat ja opit uutta."
		}
	}
</i18n>

<template>
	<div>
		<v-container
			class="container--narrow"
		>
			<!-- Output child page -->
			<router-view :key="$route.fullPath" />

			<template
				v-if="$route.name == 'subchapters'"
			>
				<v-card>
					<v-card-text>
						<FilterableList
							v-if="user._membership_subchapters && user._membership_subchapters.length"
							:items="user._membership_subchapters"
							icon-src="_icon"
							title-src="title"
							subtitle-src="_startdate"
							summary-src="_summary"
							:enable-click="(true)"
							:enable-search="(false)"
							@itemClick="itemClick"
						/>
						<v-alert
							type="info"
							v-else
						>
							{{ $i18n.t('noItemsText') }}
						</v-alert>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="secondary"
							text
							@click="openSubchapterDialog(null)"
						>
							<v-icon left>
								mdi-domain-plus
							</v-icon>
							{{ $i18n.t('applyForSubchapter') }}
						</v-btn>
					</v-card-actions>
					<!-- add subchapter application-->
					<v-dialog
						v-model="subchapterDialogOpen"
						max-width="500"
						persistent
						ref="subchapterDialog"
					>
						<v-card>
							<v-card-title>
								<template>
									{{ $i18n.t('applyForSubchapter') }}
								</template>
							</v-card-title>
							<v-divider />
							<v-card-text>
								<SchemaToForm
									ref="subchapterForm"
									:debug="false"
									:schema="config.forms.subchapterApplication"
									:disabled="subchapterSaveLoading"
									v-model="formData"
									@valid="subchapterFormValid = true"
									@invalid="subchapterFormValid = false"
								/>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									color="error"
									text
									:disabled="subchapterSaveLoading"
									@click="subchapterDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									color="success"
									text
									:disabled="subchapterSaveLoading || !subchapterFormValid"
									:loading="subchapterSaveLoading"
									@click="saveSubchapter"
								>
									{{ $i18n.t('general.save') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-card>
				<v-container class="container">
					<v-alert
						type="text"
						outlined
					>
						{{ $i18n.t('reasonToJoin') }}
					</v-alert>
				</v-container>
			</template>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'Subchapters',

	components: {
		SchemaToForm,
	},

	data: () => ({
		formData : {},
		subchapterDialogOpen: false,
		subchapterFormValid: false,
		subchapterSaveLoading: false,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	methods: {

		openSubchapterDialog () {
			this.subchapterDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.subchapterForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		saveSubchapter () {
			this.subchapterSaveLoading = true

			this.$api.Me.doRequest({
				url: 'subchapters',
				method: 'POST',
				body: this.formData,
			}).on('done', res => {
				if (!res.body.error) {
					this.$api.Config.pushRequest()
					this.subchapterDialogOpen = false
				}
			}).on('finish', () => {
				this.subchapterSaveLoading = false
			})
		},

		itemClick (item) {
			this.$router.push({ name: 'subchapter', params: { pagename: item.name } })
		},

	},
}
</script>
